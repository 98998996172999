<template>
  <button type="button" class="btn btn-primary btn-block stepper-button" @click="emitClick()">
    <b-container fluid>
      <b-row>
        <b-col cols="3">
          <font-awesome-icon
            size="3x"
            :icon="[context.iconFamily, context.iconName]"
            style="padding-right: 10px" 
          />
          
        </b-col>
        <b-col cols="9" style="padding-right: 15px">
          <b-row>
            <span class="ml-1">
              <b>{{ context.main }}</b></span
            >
          </b-row>
          <b-row>
            <span class="ml-1" style="text-align: left">
              {{ context.secondary }}</span
            >
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </button>
</template>
<script>
export default {
  name: "sButton",
  props: ["context"],
   methods: {
    emitClick: function () {
       this.$emit('clicked');
    }
   }
};

</script>
<style scoped>
.stepper-button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: rgb(27, 26, 26);
}
.stepper-button:hover {
  cursor: pointer;
  color:  #1ca8dd;
}



</style>