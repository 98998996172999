<template>
  <b-container  fluid>
    <b-row>
      <b-col cols="12" class="issuance-container"> 
          <k-input class="issuance-textbox" :label="'First Name'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
          <k-input class="issuance-textbox" :label="'Last Name'"></k-input> </b-col>
      <b-col cols="12" class="issuance-container"> 
          <k-input  class="issuance-textbox" :label="'Position'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
          <k-input class="issuance-textbox" :label="'Email'"></k-input> </b-col>
      <b-col cols="12" class="issuance-container">
          <k-input class="issuance-textbox" :label="'Phone Number'"></k-input> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {  Input } from '@progress/kendo-vue-inputs';

export default {
    components: {
        'k-input': Input,
    }
};
</script>
