<template>
  <b-row class="contact-row">
    <b-col cols="3" class="d-flex align-items-center">
      <combobox
        class="contact-textBox"
        :label="'Role'"
        :data-items="type"
        v-model="value"
        :allow-custom="true"
        
      />
    </b-col>
    <b-col cols="3" class="d-flex align-items-center">
      <k-input class="contact-textBox" :label="'Name'" :value="data.name"></k-input>
    </b-col>
    <b-col cols="3" class="d-flex align-items-center">
      <k-input class="contact-textBox" :label="'Email'" :value="data.email"></k-input>
    </b-col>
    <b-col cols="2" class="d-flex align-items-center">
      <k-input class="contact-textBox" :label="'Phone'" :value="data.phone"></k-input>
    </b-col>
    <b-col cols="1" class="d-flex align-items-end">
      <k-button @click="onClick" icon="minus"></k-button>
    </b-col>
  </b-row>
</template>
<script>
import { ComboBox } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-buttons-vue-wrapper";
export default {
  components: {
    combobox: ComboBox,
    "k-input": Input,
    "k-button": Button,
  },
  name: "ContactsInput",
  props: ["type","data"],
  data: function () {
    return {
      value: this.data.role,
    };
  },
  methods: {
    onClick() {
      this.$destroy();

      // remove the element from the DOM
      this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>
<style scoped>
.contact-textBox {
  width: 100%;
}
.contact-row {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.contact-row:hover {
  cursor: pointer;
  background-color: #b8d7ff;
  
}
</style>
