<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="issuance-container">
        <k-input
          class="issuance-textbox"
          :label="'Municipality Name'"
        ></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input
          class="issuance-textbox"
          :label="'Municipal Sub Type'"
        ></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Name of Mayor'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Date Established'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Population'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Province'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Time Zone'"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Description'"></k-input>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { Input } from "@progress/kendo-vue-inputs";

export default {
  components: {
    "k-input": Input,
  },
};
</script>

