<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Project Name'" v-model="descriptor"> </k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Asset ID'" v-model="assetId"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Asset Class'" v-model="assetClass"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Amount (CAD)'" v-model="amount"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Yield'" v-model="yieldAmount"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Term'" v-model="term"></k-input>
      </b-col>
      <b-col cols="12" class="issuance-container">
        <k-input class="issuance-textbox" :label="'Maturity'" v-model="maturity"></k-input>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { Input } from "@progress/kendo-vue-inputs";

export default {
  components: {
    "k-input": Input,
  },
   data: function () {
    return {
      descriptor:this.$parent.bond.descriptor,
      assetId: this.$parent.bond.asset_id,
      assetClass:this.$parent.bond.asset_class,
      amount:this.$parent.bond.amount,
      yieldAmount:this.$parent.bond.yield,
      term:this.$parent.bond.term,
      maturity:this.$parent.bond.maturity,
    }
   }
};
</script>

