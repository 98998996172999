<template>
  <div class="government">
    <b-container fluid>
      <k-dialog v-if="visibleDialog" @close="toggleDialog">
        <p :style="{ margin: '25px', textAlign: 'center' }">
          <span class="row">Your project was successfully submitted.</span>
          <span class="row">We will contact you with the next step.</span>
        </p>
        <dialog-actions-bar>
          <button class="k-button" @click="submitProject">OK</button>
        </dialog-actions-bar>
      </k-dialog>
    <h1 class="header-color" style="text-align: left; padding: 10px">
      <font-awesome-icon
        size="1x"
        class="header-color" :icon="['fas', 'plus']" />
        Submit your project
      </h1>
      <b-row>
        <b-col cols="4">
          <b-card class="issuance-view">
            <div class="px-3 py-2">
              <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                  <sButton
                    :class="isPrimaryActive && activeClass"
                    v-bind:context="primaryContact"
                    @clicked="loadPage('primary-contact')"
                  />
                </li>
              </ul>
              <hr class="rounded" />
              <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                  <sButton
                    v-bind:context="municipalityInfo"
                    :class="isMunicipalityActive && activeClass"
                    @clicked="loadPage('municipality-info')"
                  />
                </li>
              </ul>
              <hr class="rounded" />
              <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                  <sButton
                    v-bind:context="project"
                    :class="isProjectActive && activeClass"
                    @clicked="loadPage('project-info')"
                  />
                </li>
              </ul>
              <hr class="rounded" />
              <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                  <sButton
                    v-bind:context="municipalContacts"
                    :class="isContactsActive && activeClass"
                    @clicked="loadPage('municipal-contacts')"
                  />
                </li>
              </ul>
            </div>
          </b-card>
        </b-col>
        <b-col cols="8">
          <b-card class="issuance-view">
            <template #header>
              <div class="float-right" sticky>
                <span class="ml-1"
                  ><k-button href="#"><b>Save</b></k-button></span
                >

                <span v-if="isContactsActive" class="ml-1"
                  ><k-button @click="toggleDialog"
                    ><b>Submit</b></k-button
                  ></span
                >
              </div>
            </template>
            <keep-alive>
              <component v-bind:is="component" />
            </keep-alive>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import sButton from "@/components/stepperButton.vue";
import { Button } from "@progress/kendo-buttons-vue-wrapper";
import PrimaryContact from "@/components/IssuancePages/PrimaryContact.vue";
import MunicipalityInfo from "@/components/IssuancePages/MunicipalityInfo.vue";
import ProjectInfo from "@/components/IssuancePages/ProjectInfo.vue";
import MunicipalContacts from "@/components/IssuancePages/MunicipalContacts.vue";
import dashboard from "@/components/GovernmentDashboard.vue";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
export default {
  components: {
    sButton,
    "primary-contact": PrimaryContact,
    "municipality-info": MunicipalityInfo,
    "project-info": ProjectInfo,
    "municipal-contacts": MunicipalContacts,
    dashboard,
    "k-button": Button,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
  },
  mounted: function () {
    if (this.$route.params.id) {
      var bondID = this.$route.params.id;
      this.bond = this.$store.state.bondData.filter(
        (item) => item.bond_id == bondID
      )[0];
    }
  },
  data: function () {
    return {
      bond: {},
      visibleDialog: false,
      activeClass: "active-class",
      isContactsActive: false,
      isProjectActive: false,
      isMunicipalityActive: false,
      isPrimaryActive: true,
      component: "primary-contact",
      primaryContact: {
        main: "Primary Contact Info",
        secondary: "Setup your account",
        iconFamily: "far",
        iconName: "user",
        page: "dashboard",
      },
      municipalityInfo: {
        main: "Municipality Info",
        secondary: "Record details about where the project will take place",
        iconFamily: "far",
        iconName: "clipboard",
        page: "dashboard",
      },
      project: {
        main: "Project Info",
        secondary: "Describe your bond project",
        iconFamily: "far",
        iconName: "file-alt",
        page: "dashboard",
      },
      municipalContacts: {
        main: "Contacts",
        secondary: "Provide contact information needed for approvals",
        iconFamily: "far",
        iconName: "address-book",
        page: "dashboard",
      },
    };
  },

  methods: {
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    submitProject() {
      this.visibleDialog = !this.visibleDialog;
      this.$router.push({ name: "dashboard" });
    },
    loadPage: function (name) {
      if (name === "municipal-contacts") {
        this.isContactsActive = true;
        this.isProjectActive = false;
        this.isMunicipalityActive = false;
        this.isPrimaryActive = false;
      } else if (name === "project-info") {
        this.isContactsActive = false;
        this.isProjectActive = true;
        this.isMunicipalityActive = false;
        this.isPrimaryActive = false;
      } else if (name === "municipality-info") {
        this.isContactsActive = false;
        this.isProjectActive = false;
        this.isMunicipalityActive = true;
        this.isPrimaryActive = false;
      } else {
        this.isContactsActive = false;
        this.isProjectActive = false;
        this.isMunicipalityActive = false;
        this.isPrimaryActive = true;
      }
      this.component = name;
    },
  },
};
</script>
<style scoped>
.active-class {
  cursor: pointer;
  color:  #1ca8dd ;
  border: none;
}
</style>