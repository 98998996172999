<template>
  <b-container fluid>
    <div ref="municipal">
      <b-row>
        <b-col cols="1">
          <k-button @click="onClick(municipal, 'municipal')" icon="add"
            ><b>Municipal Contact</b></k-button
          >
        </b-col>
      </b-row>
       <contacts-input v-for="item in testMunicipalData" :key="item" v-bind:type="municipal" v-bind:data="item"/>
    </div>
    <hr class="rounded" />
    <div ref="provincial">
      <b-row>
      <b-col cols="1" >     
        <k-button @click="onClick(provincial, 'provincial')" icon="add" 
          ><b>Provincial Contact</b></k-button
        >      
      </b-col>
      </b-row>
        <contacts-input v-for="item in testProvincialData" :key="item" v-bind:type="provincial" v-bind:data="item"/>
    </div>
    <hr class="rounded" />
    <div ref="federal">
      <b-row>
      <b-col cols="1">
        <k-button @click="onClick(federal, 'federal')" icon="add"
          ><b>Federal Contact</b></k-button
        >
      </b-col>
      </b-row>
      <contacts-input v-for="item in testFederalData" :key="item" v-bind:type="federal" v-bind:data="item"/>
    </div>
  </b-container>
</template>
<script>
import { Input } from "@progress/kendo-vue-inputs";
import contactsInput from "@/components/IssuancePages/ContactsInput.vue";
import { Button } from "@progress/kendo-buttons-vue-wrapper";

import Vue from "vue";
import ContactsInput from './ContactsInput.vue';
export default {
  components: {
    "k-button": Button,
   "contacts-input": ContactsInput,
  },
  data: function () {
    return {
      testFederalData:[{name:"John Smith" ,email:"john@test.com",phone:"676234565", role:"FP Manager"},{name:"Sara Parker" ,email:"sara@test.com",phone:"676234235", role:"FP Director"}],
       testMunicipalData:[{name:"Joe Williams" ,email:"joe@test.com",phone:"674234565", role:"SME1"}],
       testProvincialData:[{name:"Jane Doe" ,email:"jane@test.com",phone:"674124565", role:"PP Manager"}],
      municipal: [
        "SME1",
        "SME2",
        "SME3",
        "Project Manager",
        "Project Director",
        "Unit Manager",
        "Unit Director",
        "Unit Commissioner",
        "Financial Manager",
        "Chief Financial Officer",
        "Treasurer",
        "Unit Chief Administrative Officer",
        "Mayor",
        "City Clerk",
      ],
      provincial: [
        "PP Manager",
        "PP Director",
        "PP Assistant Deputy Minister",
        "PP Deputy Minister",
        "PP Finance Minister",
        "Director of Government Service",
      ],
      federal: [
        "FP Manager",
        "FP Director",
        "FP Assistant Deputy Minister",
        "FP Deputy Minister",
        "FP Finance Minister",
        "Director of Government Service",
      ],
    };
  },
  methods: {
    onClick(data, sectionName) {
      var ComponentClass = Vue.extend(contactsInput);
      var instance = new ComponentClass({
        propsData: { type: data ,data:{}},
      });

      instance.$mount(); // pass nothing
      //         console.log(this.$refs)
      if (sectionName === "municipal") {
        this.$refs.municipal.appendChild(instance.$el);
      } else if (sectionName === "provincial") {
        this.$refs.provincial.appendChild(instance.$el);
      } else if (sectionName === "federal") {
        this.$refs.federal.appendChild(instance.$el);
      }
    },
  },
};
</script>

